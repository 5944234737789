@use 'styles/variables' as *;
@use 'styles/breakpoints' as *;

.networkSelectionModalWrapper {
  padding: 20px;
  max-width: 368px;
  @include media("screen", "<phone") {
    padding: 16px;
  }
}

.networkItemWrapper {
  width: 100%;
  height: 50px;
  border: 1px solid $secondary3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  border-radius: 12px;
  padding: 0 16px;
  cursor: pointer;
  & img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  @include media("screen", "<phone") {
    padding: 0 12px;
  }
}

.networkConnectedDot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: $successMain;
  margin-right: 6px;
}
