@use 'styles/variables' as *;

.slippageButton {
  width: 62px;
  height: 40px;
  border-radius: 10px;
  border: solid 1px $secondary2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 16px
}
.activeSlippageButton {
  background: $primary;
}
.expertConfirmInput {
  height: 40px;
  border: 1px solid $secondary1;
  background-color: $bgColor;
  border-radius: 10px;
  padding: 0 16px;
  margin-bottom: 20px;
  & input {
    background: transparent;
    border: none;
    outline: none;
    color: $grey23;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    width: 100%;
    height: 100%
  }
}
.expertButtonWrapper {
  cursor: pointer;
  background-color: $errorMain;
  height: 42px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.settingsInputWrapper {
  flex: 1;
  height: 40px;
  border-radius: 10px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background-color: $bgColor;
  & input {
    color: $grey23;
  }
}