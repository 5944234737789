@use 'styles/variables' as *;

.farmingAPRTooltipWrapper {
  background-color: $bgColor;
  width: 220px;
  border-radius: 14px;
  overflow: hidden;
  & > div {
    padding: 0 10px;
    & small:first-child {
      margin-right: 6px;
    }
  }
}
