@use 'styles/variables' as *;

.customMenuWrapper {
  position:relative;
  height: 100%;
  width: 100%;
}

.customMenuHeader {
  border-radius: 10px;
  border: 1px solid $grey2;
  height: 100%;
  width: 100%;
  padding: 0 8px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $textSecondary;
  cursor: pointer;
}
.customMenuContent {
  border-radius: 10px;
  border: 1px solid $grey2;
  padding: 12px;
  background: $bgColor;
  position: absolute;
  z-index: 10;
  margin-top: 8px;
  width: 100%;
  & > div {
    cursor: pointer;
    margin: 8px 0;
  }
}