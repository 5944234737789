@use 'styles/variables' as *;
@use 'styles/breakpoints' as *;

.meldModalWrapper {
  max-width: 450px;
  background-color: rgb(12, 17, 42) !important;
  display: flex;
  flex-direction: column;
}

.meldModalClose {
  margin: 8px 8px 0 0;
  display: flex;
  justify-content: flex-end;
}

.buyFiatContent {
  flex: 1;
  overflow-y: auto;
  & iframe {
    border: none;
  }
}