@use 'styles/variables' as *;

.chartType {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  cursor: pointer;
  &.chartType_big {
    height: 33px;
    padding: 0 12px;
    & span {
      font-size: 14px;
    }
  }
  &.chartType_selected {
    & span {
      color: $textPrimary
    }  
  }
}